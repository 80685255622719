import React from 'react'
import Layout from '../components/Layout'
import PaddedLayout from '../components/PaddedLayout'
import '../styles.scss'
export default function Discover() {
    return (
        <Layout>
            <PaddedLayout>
                <div className="page-wrapper">
                <h1>Discover</h1>
                </div>
            </PaddedLayout>
        </Layout>
    )
}
